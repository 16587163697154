import { MutableRefObject, useCallback } from "react";
import { getAmazonRestrictedAllAsins } from "api/amazon";

// Returns a list of results and marks each result with an extra value - restrictions
// marked according to the mysql table amazon_items_restrictions
const useRestrictionsAmazon = () => {

    const postFetchFn = useCallback(async (result: any, refForAbortSignal?: MutableRefObject<any>) => {
        const controller = new AbortController();
        if (refForAbortSignal) refForAbortSignal.current = controller
        const asins = result?.rows?.map((row: any) => row?.asin) || []
        if (asins?.length) {
            const tt = Date.now()
            const data = await getAmazonRestrictedAllAsins(asins, controller)
            const map = new Map()
            data.forEach((a: any) => {
                const old = map.get(a.asin)
                if (!old) {
                    map.set(a.asin, [a])
                } else {
                    map.set(a.asin, [...old, a])
                }
            })
            result = {
                ...result,
                rows: result.rows.map((row: any) => ({
                    ...row,
                    restrictions: map.get(row.asin)
                }))
            }
        }
        return result
    }, [])


    return {
        postFetchFn
    }
}


export default useRestrictionsAmazon
