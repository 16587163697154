import {
  useMemo,
  useRef,
  useState,
  useCallback
} from "react";

export const useTableForTabs = ({
  isVisible
}: {
  isVisible: boolean
}) => {

  const [isFetchDone, setFetchDone] = useState(false)

  const refForRefetch = useRef(0)
  const needRefetch = useMemo(() => {
    if (!isVisible) return refForRefetch.current
    const time = Math.floor(new Date().getTime())
    refForRefetch.current = time;
    return refForRefetch.current
  }, [isVisible, refForRefetch])

  const _setFetchDone = useCallback(() => {
    setFetchDone(true)
  }, [setFetchDone])


  return useMemo(() => {
    return {
      hideLoading: !!isFetchDone,
      needRefetch,
      setFetchDone: _setFetchDone
    }
  }, [needRefetch, _setFetchDone, isFetchDone])
}
