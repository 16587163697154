


export enum EnumTableIdsInSystem {
    legacyOrder365 = 0x365009,
    legacyOrder365Plus = 0x365010,

    legacyOrder365OptiPlus = 0x365011,
    legacyOrderBuffersPlus = 0x365012,
    legacyOptiScoreSecuredNew = 0x650011,
    legacyOptiScoreSecuredUsed = 0x650012,
    legacyOptiScoreSecuredUsedNewVersion = 0x650015,





    booksBuffersBufferQty = 0x865011,

    tableTags = 0x795010,
}



