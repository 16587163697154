import React, { useMemo } from 'react'
import {
  RecordValidityContainer,
  RecordValidityHeader,
  RecordValidityHeaderTitle,
  RecordValidityContent,
  RecordValidityValueContainer
} from "pages/Legacy/Order/365/RecordValidity/style";
import { formatDateTime } from "Utils";
import { AppDivider } from "style";
import RenderLabelValue from "components/Utils/Render";
const RecordValidity = ({ row, isUsed }: any) => {

  const [store, lastTimeNotify, diff] = useMemo(() => [
    (() => {
      const ourSeller = row?.original?.amazonOurSeller
      switch (ourSeller) {
        case 0x8: return 'Syracuse Unlimited'
        case 0x4: return 'C.&.H'
        case 0x10: return 'Noggn'
        case 0x20: return 'TruBrave'
        case 0x40: return 'RSM'
        case 0x100: return 'Wx'
        case 0x2: return 'Sammy\'s Club'
      }
    })(),
    row?.original?.amazonLastTimeNotify ? formatDateTime(row?.original?.amazonLastTimeNotify) : '',
    (() => {
      const _time = row?.original?.lastTimeFetch
      if (!_time) return
      const currentTime = new Date().getTime() as any
      const time = new Date(_time).getTime() as any
      const diffMs = (currentTime - time);
      const diffMinutes = Math.round((currentTime - time) / 60000);
      const diffDays = Math.floor(diffMs / 86400000);
      const diffHrs = Math.floor((diffMs % 86400000) / 3600000);
      if (diffMinutes > 60 && diffHrs > 24) return `${diffDays} day${diffDays > 1 ? 's' : ''} ago`
      if (diffHrs < 24 && diffMinutes > 60) return `${diffHrs} hour${diffHrs > 1 ? 's' : ''} ago`
      if (diffMinutes < 60) return `${diffMinutes} min${diffMinutes > 1 ? 's' : ''} ago`
    })()
  ], [row, isUsed])

  const Content = () => {
    if (isUsed) {
      return (
        <RecordValidityContent isUsed>
          <RenderLabelValue label={'Time Sync'} isUpperCase={false} value={diff} isDivider />
        </RecordValidityContent>
      )
    }
    return (
      <RecordValidityContent>
        <RenderLabelValue label={'Store name'} isRow isUpperCase={false}>
          <RecordValidityValueContainer>
            <div>Time Sync</div>
            <div>Time Notify</div>
          </RecordValidityValueContainer>
        </RenderLabelValue>
        <AppDivider />
        <RenderLabelValue label={store} isRow>
          <RecordValidityValueContainer>
            <div>{diff}</div>
            <div>{lastTimeNotify}</div>
          </RecordValidityValueContainer>
        </RenderLabelValue>
      </RecordValidityContent>
    )
  }

  return (
    <RecordValidityContainer isUsed={isUsed}>
      <RecordValidityHeader>
        <RecordValidityHeaderTitle>Record Validity</RecordValidityHeaderTitle>
      </RecordValidityHeader>
      <AppDivider />
      <Content />
    </RecordValidityContainer>
  )
}

export default RecordValidity
